<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-900px">
            <div>
                <p class="title">PBC 샘플</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>            
            <div class="con-top mb-20">
                <div class="con-top-txt">
                    - 사업부 목표에 기여할 개인 업무목표 및 달성방안을 구체적으로 작성.<br>
                    - 가능한 Output (Performance)를 계수화 시킬 것!<br>
                    - Project나 업무 단위 별 달성목표 위주로 기록<br>
                    - S.M.A.R.T 원칙 적용 (Specific, Measurable, Action-Oriented, Realistic, Time-bound)<br>
                    - 내용은 구체적이어야 하고, 측정가능하고, 달성가능하고, 현실적이며 시간제약적으로 기재<br>
                    - Leadership 작성 : B.L 및 Manager급은 조직에 대한 Leadership, Staff급은 Self-Leadership 작성
                </div>
            </div>            
            <div class="mt-10 tbl-light overhidden overscroll maxh-450px">
                <div class="block-title">CARROT Personal Business Commitment (2019)</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="80">
                        <col width="*">
                        <col width="220">
                        <col width="220">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>항목</th>
                            <th>내용(핵심 KPI)</th>
                            <th>Time-bound</th>
                            <th>계량적 기여도</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowspan="3">주요<br>업무<br>목표</th>
                            <td>
                                1. 2014년 총 매출 목표<br>
                                월 평균 4000*12 = 년간 4억 8천<br>
                                <br>
                                &lt;  Big 고객사 &gt;<br>
                                1) 이마트 사내어학과정 (4차수운영) - 3억5천<br>
                                2) KTDS 1:1 코칭 10 class 운영 - 4천<br>
                                3) 인천국제공항공사 임원과정 및 인텐시브과정<br>
                                <br>
                                &lt; 인텐시브 과정 &gt;<br>
                                1) 한화 케미칼 인텐시브 과정<br>
                                2) 사전, 사후 학습 프로그램 유도<br>
                                &nbsp;&nbsp;&nbsp;- 월 300만원 목표<br>
                                3) 주 증대 유도 (4주->6주)<br>
                                &nbsp;&nbsp;&nbsp;- 차수 당 2000만원 증대 목표<br>
                            </td>
                            <td class="txt-center">
                                2014년
                            </td>
                            <td>
                                2014년 10억 매출<br>
                                2013년 대비 60% 성장 목표
                            </td>
                        </tr>
                        <tr>
                            <td>
                                2. Value Marketing<br>
                                * 이마트<br>
                                - 사내어학과정 성공적 유치<br>
                                오프라인 블렌디드+전화영어+차이나통<br>
                                - 1차수 후 클래스 확대개설<br>
                                - 타과정으로 확대(1:1코칭)<br>
                                * KTDS<br>
                                - 2클래스 오픈 예정이었으나 현재 코칭 10클래스확정<br>
                                - 계열사 KTDS에서 KT 다른 계열사까지 확대<br>
                                * 인천국제공항공사<br>
                                - 비딩으로 타업체와 공동진행 확정<br>
                                - 이것을 기회로 신뢰도 향상 및 단독 진행
                            </td>
                            <td class="txt-center">
                                2014년
                            </td>
                            <td>
                                Value Marketing Class 30 Class 증대
                            </td>
                        </tr>
                        <tr>
                            <td>
                                3. 조직 내에서의 역할<br>
                                - 팀원간의 커뮤니케이션 채널 허브 역할<br>
                                - Q.C팀의 일환으로 교육질 선도 및 전파<br>
                                - 신규 입사자 교육 전담 및 효율적 정착 유도<br>
                                - 파트장으로서 파트원 교육 및 회사와 방침 일치화
                            </td>
                            <td class="txt-center">
                                2014년 3월 이후
                            </td>
                            <td>
                                팀매출 50% 증대 기여
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div class="block-title mt-40">CARROT 역량 계발 목표 (2019)</div>
                <table class="tbl-light-view mt-10">
                    <colgroup>
                        <col width="150">
                        <col width="*">
                        <col width="150">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>항목</th>
                            <th>내용</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>교육 및 <br>전문지식 계발</th>
                            <td>
                                1. 전화 일본어 수업을 통한 기본적인 일본어 의사 소통 능력 함양과 당근영어의 일본어 수업의 퀄리티 컨트롤<br>
                                2. OPIc, TOEIC SPEAKING TEST 연구, 분석을 통한 학생들에게 전문적인 컨설팅 제공<br>
                                3. Excel 과 PPT 교육 이수를 통해 관리 작업 시간을 단축하고 효율적으로 진행.
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <th>창의성 및 <br>문제 해결능력</th>
                            <td>
                                1. 학습자들의 개인적 Needs 나 Strength &amp; Weaknesses를 ㅠㅏ악하여 커리큘럼을 작성 -> 학습자들을 수업 만족도 최대화<br>
                                2. 주기적인 참관과 강사와의 유기적 Contact를 통해, 학생 및 강사틀에서 일어날 수 있는 문제를 사전에 방지<br>
                                3. 학습자들을 위한 학습 TIP, 컨텐츠, Sub Material 적극 지원
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <th>근태</th>
                            <td>
                                1. 지난해에는 빠른 적응을 위한 빠른 출근이었다면, 올해에는 기업분석 및 교육과정 기획, 개발의 새로운 과정을 위해, 업무 전 시간 활용 예정<br>
                                2. 지나친 야근과 주말 근무를 지양하고 주중에 빠르고 정확하게 일 처리를 하도록 노력 한다.
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <th>Leadership</th>
                            <td>
                                1. 익격 소양을 위한 책을 월 2회 읽으며 자아반성 한다.(멘토링 도서 제외)<br>
                                2.타인의 고유성을 인지하고 출처가 불분명한 소문, 타인의 주관적인 평가 등으로 흐린 눈과 귀를 가진 리더가 아닌 맑은 눈과 귀로 보고 듣는 리더가 되기 위해 노력한다.<br>
                                3. 직장 동료들에게 귀감이 될 수 있도록 효율적이며 생산적으로 일하고, 또 항상 공부하고 연구하는 내가 된다.<br>
                                4. 나의 신념과 방법을 최고라 자부 하지 않고 강요 하지 않는 리더가 되기 위해 노력한다.
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <th>CK과 <br>나의 비전</th>
                            <td>
                                1. 회사와 나의 비전을 동일하게 - 나의 발전이 곧 회사의 발전<br>
                                2. 고객이 원하는 것이 모두 준비된 당근이 된다.<br>
                                국내 최고의 강사진, 매니저, 교수법, 교재를 통해 교육업의 선두주자가 된다.
                            </td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <p class="tip-c1 mt-5 mb-10">* Leadership 작성 : B.L 및 Manager급은 조직에 대한 Leadership, Staff급은 Self-Leadership 작성</p>
            </div>
            
        </div>
    </div>
</template>

<script>
import { reactive } from 'vue'

export default {
    name: 'PBCSamplePopup',
    props: {
        idx: {
            type: Number,
            default: 0,
        }
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });
        
        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>