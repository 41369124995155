<template>
    <div class="con-wrap">
        <PBCSamplePopup v-if="pop.isShow" @close="pop.isShow=false"></PBCSamplePopup>
        <CarrotTitle title="PBC 작성">
            <div class="btn-ibbox title-btnbox float-right">
                <button class="btn-default" @click="pop.isShow=!pop.isShow">샘플보기</button>
            </div>
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>
                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="150">
                            <col width="210">
                            <col width="110">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>팀원</th>
                                <th>직급</th>
                                <th>입사일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ add.info.sector_name }}</td>
                                <td>{{ add.info.office_name }}</td>
                                <td>{{ add.info.team_name }}</td>
                                <td>{{ add.info.ename }}({{ add.info.kname }})</td>
                                <td>{{ add.info.position_name }}</td>
                                <td>{{ add.info.joindate }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left mt-10">CARROT Personal Business Commitment ({{ add.year }})</span>
                        <button class="btn-default float-right" @click="add.addItem">+ 항목추가</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="300">
                            <col width="300">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>항목</th>
                                <th>내용(핵심 KPI)</th>
                                <th>Time-bound</th>
                                <th>계량적 기여도</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th :rowspan="add.list.length">주요<br>업무<br>목표</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.list[0].content"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.list[0].timebound"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.list[0].contribution"></textarea>
                                </td>
                            </tr>
                            <tr v-for="(irow, i) in add.list.slice(1, add.list.length)" :key="i">
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="irow.content"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="irow.timebound"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="irow.contribution"></textarea>
                                </td>
                            </tr>
                            <!-- <tr>
                                <td>
                                    <textarea class="w-100per h-100px"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per h-100px"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per h-100px"></textarea>
                                </td>
                            </tr> -->
                        </tbody>
                    </table>

                    <div class="mt-50 mb-20">
                        <span class="block-title float-left">CARROT 역량 계발 목표 ({{ add.next_year }})</span>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="150">
                            <col width="*">
                            <col width="400">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>항목</th>
                                <th>내용</th>
                                <th>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>교육 및 <br>전문지식 계발</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.ens_cont"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.ens_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>창의성 및 <br>문제 해결능력</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.cns_cont"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.cns_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>근태</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.att_cont"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.att_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>Leadership</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.lds_cont"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.lds_memo"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th>CARROT과 <br>나의 비전</th>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.myv_cont"></textarea>
                                </td>
                                <td>
                                    <textarea class="w-100per minh-100px" style="min-height: 100px" v-model="add.myv_memo"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <button class="btn-default float-left mt-30" @click="add.goList">목록</button>
                    <button class="btn-default float-right mt-30 ml-20" @click="add.doSubmit">제출</button>
                    <button class="btn-default float-right mt-30" @click="add.tempSave">임시저장</button>
                    <div class="clear"></div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import { onMounted, onUnmounted, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2'
import PBCSamplePopup from '@/components/popup/myPIMS/PBCSamplePopup.vue'


export default {
    layout:"myPIMS",
    components: {
        PBCSamplePopup
    },
    setup() {
        const router = new useRouter();
        const route  = new useRoute();
        const toast = useToast()

        const pop = reactive({
            // Popup 노출 여부
            isShow: false
        });

        const add = reactive({
            // New Post
            idx  : 0,
            year : "",
            info : { 
                idx:0, office_name:"", team_name:"", ename:"", kname:"", position_name:"", joindate:""
            },
            list : [
                { content:"", timebound:"", contribution:"" },
                { content:"", timebound:"", contribution:"" },
                { content:"", timebound:"", contribution:"" },
            ],

            addItem : () => {
                add.list.push(
                    { content:"", timebound:"", contribution:"" }
                );
            },

            doSearch : () => {
                axios.get("/rest/mypims/getMyPBC", { params : {} }).then((res) => {
                    if( res.data.err == 0 ){
                        add.info = res.data;

                        if( res.data.hasTemp == "Y" ) {
                            add.idx  = res.data.temp.idx;
                            add.year = res.data.temp.wyear;
                            add.list = res.data.temp.list;
                            add.ens_cont = res.data.temp.ens_cont;
                            add.ens_memo = res.data.temp.ens_memo;
                            add.cns_cont = res.data.temp.cns_cont;
                            add.cns_memo = res.data.temp.cns_memo;
                            add.att_cont = res.data.temp.att_cont;
                            add.att_memo = res.data.temp.att_memo;
                            add.lds_cont = res.data.temp.lds_cont;
                            add.lds_memo = res.data.temp.lds_memo;
                            add.myv_cont = res.data.temp.myv_cont;
                            add.myv_memo = res.data.temp.myv_memo;
                        }
                    } else {
                        console.info(res);
                    }
                });
            },

            tempSave : () => {
                let params = {
                    idx      : add.idx,
                    wyear    : add.year,
                    goals    : add.list,
                    ens_cont : add.ens_cont,
                    ens_memo : add.ens_memo,
                    cns_cont : add.cns_cont,
                    cns_memo : add.cns_memo,
                    att_cont : add.att_cont,
                    att_memo : add.att_memo,
                    lds_cont : add.lds_cont,
                    lds_memo : add.lds_memo,
                    myv_cont : add.myv_cont,
                    myv_memo : add.myv_memo,
                    state    : "T"
                };

                axios.post("/rest/mypims/addPBC", params).then((res) => {
                    if( res.data.err == 0 ){
                        add.idx = res.data.idx;
                        toast.success("임시저장이 완료 되었습니다.");
                    } else if(res.data.err === 802) {
                        toast.error('CARROT 역량 계발 목표 내용이 너무 깁니다.')
                    } else {
                        console.info(res);
                    }
                });
            },

            doSubmit : () => {
                let params = {
                    idx      : add.idx,
                    wyear    : add.year,
                    goals    : add.list,
                    ens_cont : add.ens_cont,
                    ens_memo : add.ens_memo,
                    cns_cont : add.cns_cont,
                    cns_memo : add.cns_memo,
                    att_cont : add.att_cont,
                    att_memo : add.att_memo,
                    lds_cont : add.lds_cont,
                    lds_memo : add.lds_memo,
                    myv_cont : add.myv_cont,
                    myv_memo : add.myv_memo,
                    state    : "Y"
                };

                Swal.fire({
                    title : 'PBC 작성',
                    text  : '제출 후에는 수정이 불가합니다. 제출하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        axios.post("/rest/mypims/addPBC", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("제출하였습니다.");
                                router.push({
                                    name : "myPIMS-PBCKPIList"
                                });
                            } else if(res.data.err === 802) {
                                toast.error('CARROT 역량 계발 목표 내용이 너무 깁니다.')
                            } else {
                                console.info(res);
                            }
                        });
                    }
                });
            },

            goList : () => {
                if( confirm("입력하신 내용이 사라집니다. 목록으로 이동하시겠습니까?") == true ){
                    router.push({
                        name : "myPIMS-PBCKPIList",
                        params : route.params
                    });
                }
            }
        });
        
        onMounted(() => {
            let td = new Date();
            add.year = td.getFullYear() ;
            add.next_year = td.getFullYear() + 1;
            add.doSearch();
        });

        onUnmounted(() => {

        });

        return {pop, add};
    }
}
</script>

<style lang="scss" scoped>
</style>